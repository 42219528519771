<template>
  <v-card
    outlined
    class="pa-2 t-card"
  >
    <div class="d-flex justify-space-between align-center">
      <div class="d-flex align-center flex-wrap">
        <span
          class="t-room-id cl-name"
        >
          {{ name }}
        </span>
      </div>
      <span class="cl-name --small">{{ roomType }}</span>
    </div>
  </v-card>
</template>

<script>
// import { listContractColor } from '@/constants/contractColors'

export default {
  name: 'GuestRoomItemEmpty',
  mounted () {
  },
  methods: {
  },
  data () {
    return {
    }
  },
  props: {
    id: Number,
    name: String,
    roomType: String
  }
}
</script>

<style lang="scss" scoped>
* {
  color: var(--text__gray-darken);
  font-size: 12px;
}
.t-room-id {
  font-size: 16px;
  font-weight: 600;
}
.cl-name {
  color: #aaaaaa;
  &.--small {
    font-size: 11px;
  }
}

::v-deep {
  &.t-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 139px !important;
  }
}
</style>
