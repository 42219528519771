<template>
  <BaseGuestRoomDailyReport>
    <template v-slot:default>
      <div class="mt-5 flex-header">
        <v-btn @click="date = nextOrPrevDay(date, -1)" icon><v-icon size="35">mdi-chevron-double-left</v-icon></v-btn>
        <v-btn @click="date = nextOrPrevDay(date, +1)" icon><v-icon size="35">mdi-chevron-double-right</v-icon></v-btn>
        <v-btn
          @click="date = $today()"
          outlined
          small
          class="mr-5 ml-1"
          color="#000000"
        >
          {{ $t("common.toDay") }}
        </v-btn>

        <!-- Date picker -->
        <v-menu
          v-model="menuDate"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on }">
            <v-btn v-model="date" v-on="on" small class="btn--date mr-5">
              {{ formatDate(date) }}
            </v-btn>
          </template>
          <v-date-picker
            v-model="date"
            :first-day-of-week="0"
            :locale="$i18n.locale"
            scrollable
            @input="menuDate = false"
            class="v-date-picker-custom"
          ></v-date-picker>
        </v-menu>
        <!--  -->

        <span style="color:#000000; font-size: 12px">{{ $t("common.facility") }}</span>
        <FacilitySelect v-model="facilitySelected" class="ml-1" />

        <v-btn :disabled="!isBefore" :class="{'btn-cus-primary' : !isBefore}" @click="filterBeforeUpdate()" class="btn-cus-default ml-10">
          {{ $t("buttons.beforeDailyUpdate") }}
        </v-btn>

        <v-btn :disabled="isBefore" :class="{'btn-cus-primary' : isBefore}" @click="filterAfterUpdate()" class="btn-cus-default ml-5">
          {{ $t("buttons.afterDailyUpdate") }}
        </v-btn>
      </div>
      <!-- START Booking-list-from-API -->
      <v-layout v-if="roomTypeList.length > 0" wrap mt-2 justify-flex-start class="t-list-room mt-15">
        <template v-for="item in roomTypeList">
          <template v-if="filterBookings(item.bookings).length">
            <v-flex
              v-for="booking in filterBookings(item.bookings)"
              :key="`roomEmpty-${item.id}-${booking.id}`"
              class="t-room-item"
            >
              <div class="t-room-item__content" >
                <GuestRoomItem
                  @reloadData="reloadData"
                  :isFullRole="checkUser"
                  :reservationId="booking.id"
                  :roomName="item.name"
                  :roomType="item.nameRoomType"
                  :isEmpty="false"
                  :representative="booking.representative"
                  :bookingTypeCode="booking.bookingType.code"
                  :bookingBarNote="booking.bookingBarNote"
                  :contract="booking.contract"
                  :guests="booking.guests"
                  :checkComputed="booking.computed"
                  :isRental="booking.computedForList.hasRentalItem"
                  :isParking="booking.computedForList.hasParking"
                  :hasRemarks="!!booking.remarks"
                  :isBlock="booking.blockRoom"
                  :hasLink="linkedBookings[booking.id]"
                  :realDays="checkRealDays(date, booking.checkInDate)"
                  :totalDays="getTotalDays(booking.checkOutDate, booking.checkInDate)"
                  :item-root="booking"
                  :needsPetPolicyCheck="booking.room.roomType.acceptsPet && !booking.hasAgreedPetPolicy"
                />
              </div>
            </v-flex>
          </template>
          <v-flex
            v-else
            :key="`roomEmpty-${item.id}`"
            class="t-room-item"
          >
            <div class="t-room-item__content">
              <GuestRoomItemEmpty
                :id="item.id"
                :isEmpty="false"
                :name="item.name"
                :roomType="item.nameRoomType"
              />
            </div>
          </v-flex>
        </template>
      </v-layout>
      <h5 v-else class="text-center text-h6 my-15 py-15">
        {{ $t('rules.noData') }}
      </h5>
      <!-- END Booking-list-from-API -->
    </template>
  </BaseGuestRoomDailyReport>
</template>

<script>
import { checkPermissionUserCurrent } from '@/utils/permissions'
import BaseGuestRoomDailyReport from '@/components/GuestRoomDailyReport/BaseGuestRoomDailyReport'
import GuestRoomItem from '@/components/GuestRoomItem'
import GuestRoomItemEmpty from '@/components/GuestRoomItem/roomEmpty'
import { formatDate, handlErrorView, getCopyErrorTextView } from '@/constants/functions'
import { BOOKING_LIST, ROOM_TYPE_LIST_WITH_FACILITY } from '@/api/graphql/guestRoomDailyReport/booking-list'
import { nextOrPrevDay } from '@/utils/handleDate'
import { mapMutations, mapGetters } from 'vuex'
import { areLinked } from '@/utils/booking'
import gql from 'graphql-tag'
import FacilitySelect from '../../../components/Input/FacilitySelect.vue'
import { compareRoom } from '@/utils/room'

export default {
  name: 'GuestRoomPanel',
  computed: {
    ...mapGetters(['getRoleUser']),
    bookingsByRoomType () {
      const result = {}
      this.bookingList.items.forEach(b => {
        if (!result[b.room.roomType.id]) result[b.room.roomType.id] = []
        result[b.room.roomType.id].push(b)
      })

      return result
    },
    linkedBookings () {
      const result = {}
      this.bookingList.items.forEach(b => {
        result[b.id] = false
        const otherBookings = this.bookingsByRoomType[b.room.roomType.id]
        if (otherBookings && otherBookings.some(other => areLinked(b, other))) {
          result[b.id] = true
        }
      })
      return result
    }
  },
  watch: {
    async facilitySelected (newVal) {
      if (newVal) {
        await this.getBookingList()
        await this.roomTypeListWithFacility(this.facilitySelected)
      }
    },
    async date (newVal) {
      if (newVal) {
        await this.getBookingList()
        await this.roomTypeListWithFacility(this.facilitySelected)
      }
    }
  },
  data () {
    return {
      checkUser: checkPermissionUserCurrent(this.$router.currentRoute),
      bookingList: {
        items: []
      },
      roomTypeList: [],
      facilitySelected: null,
      date: this.$today(),
      menuDate: false,
      isBefore: true,
      roomBooked: null
    }
  },
  methods: {
    ...mapMutations(['setCopyErrorText', 'setErrorDialog', 'setLoadingOverlayShow', 'setLoadingOverlayHide']),
    handlErrorView,
    getCopyErrorTextView,
    formatDate,
    nextOrPrevDay,
    async reloadData () {
      await this.getBookingList()
      this.roomTypeListWithFacility(this.facilitySelected)
    },
    async getBookingList () {
      this.setLoadingOverlayShow()
      const variables = {
        filter: {
          fromDate: this.date,
          toDate: this.date,
          cancelled: false,
          facilityId: parseInt(this.facilitySelected)
        },
        orderBy: [
          { fieldName: 'roomType.id', direction: 'ASC' },
          { fieldName: 'room.id', direction: 'ASC' }
        ]
      }
      await this.$apollo.query({
        query: gql`${BOOKING_LIST}`,
        variables: variables,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.setLoadingOverlayHide()
        this.roomBooked = data.data.bookingList.items.map((item) => item.room)
        this.bookingList.items = data.data.bookingList.items
      }).catch(async (error) => {
        this.setLoadingOverlayHide()
        this.setCopyErrorText(this.getCopyErrorTextView(BOOKING_LIST, variables, error.graphQLErrors))
        const errorTmp = await this.handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
        if (errorTmp) {
          this.setAlertError(errorTmp, { root: true })
        }
      })
    },
    async roomTypeListWithFacility (facilityId) {
      this.setLoadingOverlayShow()
      const variables = {
        facilityId: parseInt(facilityId)
      }
      await this.$apollo.query({
        query: gql`${ROOM_TYPE_LIST_WITH_FACILITY}`,
        variables: variables,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.setLoadingOverlayHide()
        // let roomAll = data.data.roomTypeList.map((item) => {
        //   if (item.rooms.length > 0) {
        //     item.rooms.map((ite) => {
        //       ite.nameRoomType = item.name
        //       return ite
        //     })
        //     item.rooms.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))
        //   }
        //   return item
        // })
        // roomAll.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))
        const roomAll = data.data.roomTypeList.map((rt) => {
          rt.rooms.forEach(r => {
            r.nameRoomType = rt.name
          })
          return rt.rooms
        }).flat()
          .filter(r => r.isListed) // TO2020-1078 hide unlisted rooms
          .sort((a, b) => compareRoom(a, b)); // sort purely by room name
        roomAll.map((item) => {
          item.isBooked = false
          item.bookings = []
        })
        if (this.roomBooked.length > 0) {
          this.roomBooked.map((book) => {
            const findIndexRoom = roomAll.findIndex((item) => parseInt(item.id) === parseInt(book.id))
            roomAll[findIndexRoom] = {
              ...roomAll[findIndexRoom],
              isBooked: true,
              bookings: this.bookingList.items.filter(item => item.room.id === book.id)
            }
          })
        }
        this.roomTypeList = roomAll
      }).catch(async (error) => {
        this.setLoadingOverlayHide()
        this.setCopyErrorText(this.getCopyErrorTextView(ROOM_TYPE_LIST_WITH_FACILITY, variables, error.graphQLErrors))
        const errorTmp = await this.handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
        if (errorTmp) {
          this.setAlertError(errorTmp, { root: true })
        }
      })
    },
    filterBeforeUpdate () {
      this.isBefore = !this.isBefore
    },
    filterAfterUpdate () {
      this.isBefore = !this.isBefore
    },
    checkRealDays (date, checkInDateString) {
      const nowDate = new Date(date)
      const checkInDate = new Date(checkInDateString)
      const days = ((nowDate.getTime() - checkInDate.getTime()) / (1000 * 60 * 60 * 24)) + 1
      return Math.ceil(days)
    },
    getTotalDays (checkOutDateString, checkInDateString) {
      const checkOutDate = new Date(checkOutDateString)
      const checkInDate = new Date(checkInDateString)
      const days = (checkOutDate.getTime() - checkInDate.getTime()) / (1000 * 60 * 60 * 24)
      return Math.ceil(days)
    },
    checkLink (bookingList) {
      const toDay = new Date().toISOString().substr(0, 10)
      const bookingsWithLink = bookingList
        .filter(b => b.checkOutDate === toDay)
        .filter(b1 => bookingList.some(b2 => b1.room.roomTypeId === b2.room.roomTypeId && b2.checkInDate === toDay &&
          b1.representative?.clientId === b2.representative?.clientId &&
          b1.representative?.subMemberId === b2.representative?.subMemberId))
      return bookingsWithLink.length > 0
    },
    filterBookings (bookings) {
      const r = bookings.filter(b => (!this.isBefore && b.checkOutDate === this.date) ||
        (this.isBefore && b.checkInDate === this.date) ||
        (b.checkOutDate !== this.date && b.checkInDate !== this.date)
      )
      return r
    }
  },
  components: {
    BaseGuestRoomDailyReport,
    GuestRoomItem,
    GuestRoomItemEmpty,
    FacilitySelect
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  &.t-room-item {
    @media (min-width: 992px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @media (min-width: 992px) {
      flex: 0 0 25%;
      max-width: 25%;
    }
    @media (min-width: 1350px) {
      flex: 0 0 16.6667%;
      max-width: 16.6667%;
    }
    .t-card {
      margin: 0 2px 3px 2px;
    }
    &__content {
      height: calc(100% - 3px) !important
    }
  }
  &.t-list-room {
    max-height: 74vh;
    overflow-y: auto;
  }
}
.btn-cus {
  &-default {
    background-color: #DFECFB !important;
    color: #fff !important;
  }
  &-primary {
    &.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
      background-color: #1873d9 !important;
      color: #fff !important;
    }
  }
}
::v-deep{
  .v-btn {
    .v-btn__content{
      font-size: 10px !important;
      font-weight: 500;
    }
  }
  .btn-cus-default{
    .v-btn__content{
      font-size: 12px !important;
      font-weight: 500;
    }
  }
}
</style>
