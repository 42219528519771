<template>
  <v-menu v-model="menu" absolute offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-card
        outlined
        class="pa-2 t-card guest-panel-room"
        v-bind="attrs"
        v-on="on"
        :class="{ 'is-block': !!isBlock }"
        :style="
          isEmpty ? '' : { borderColor: getContractColor(bookingTypeCode) }
        "
      >
        <div class="d-flex justify-space-between align-center flex-wrap">
          <div class="d-flex align-center flex-wrap">
            <span
              class="t-room-id"
              :class="isEmpty ? 'text--inactive' : ''"
            >
              {{ roomName }}
            </span>
            <template v-if="isBlock === false && isEmpty === false">
              <v-chip
                small
                color="var(--bg__red-dark)"
                v-if="itemRoot.checkInDate <= isoDate(new Date()) && !itemRoot.checkedInAt"
                class="ml-1"
              >
                {{ $t("common.notIn") }}
              </v-chip>
              <v-chip
                small
                color="var(--bg__red-dark)"
                v-if="itemRoot.checkOutDate <= isoDate(new Date()) && !itemRoot.checkedOutAt"
                class="ml-1"
                width="auto !important"
              >
                {{ $t("common.notOut") }}
              </v-chip>
              <template v-if="checkComputed">
                <v-chip
                  small
                  color="var(--bg__red-dark)"
                  v-if="checkComputed.needsInvoice && itemRoot.checkOutDate <= isoDate(new Date())"
                  class="mx-1"
                >
                  未発行
                </v-chip>
              </template>
            </template>
          </div>
          <span :class="{'text--inactive': isEmpty}">{{ roomType }}</span>
        </div>
        <template v-if="isEmpty === false">
          <template v-if="isBlock === false">
            <div class="d-flex justify-space-between">
              <div class="t-guest-name d-flex">
                <img v-if="hasLink" class="mr-1" src="@/assets/images/link-icon.png" alt="link">
                <template v-if="representative">
                {{
                  representative.name ? representative.name : $t("booking.representativeUnregistered")
                }}
                </template>
                <template v-else>
                  {{ $t("booking.representativeUnregistered") }}
                </template>
              </div>
              <template v-if="needsPetPolicyCheck">
                <div class="text--red">愛犬未同意</div>
              </template>
            </div>
            {{ bookingTypeCode + " " + (bookingBarNote || '') }}
            <div v-if="contract" class="small-text">
              <template v-if="contract.client.memberId">
                <!-- {{ $t("common.member") + "ID:" + contract.client.memberId + " " }} -->
                会員番号 {{ contract.client.memberId + " " }}
              </template>
              {{ $t("common.member") + ":" + contract.client.name }}
            </div>
          </template>

          <div v-else>{{ $t("common.block") }}</div>

          <div
            class="d-flex justify-space-between align-center"
            :class="isBlock === true ? 'align-self-end' : ''"
          >
            <template v-if="isBlock === false">
              <div class="small-text">
                {{
                  $t("common.large") +
                    numOfAdult +
                    " " +
                    $t("common.small") +
                    numOfChild +
                    " " +
                    $t("common.bedSharing") +
                    numOfBedSharing
                }}
              </div>
              <div class="d-flex align-center" v-if="isParking || isRental || hasRemarks">
                <v-icon v-if="isParking" dense>mdi-car</v-icon>
                <span class="t-landing small-text" v-if="isRental">
                  {{ $t("common.loan") }}
                </span>
                <v-img class="ml-1" src="@/assets/images/has_remarks.png" v-if="hasRemarks" width="20" height="20" />
              </div>
            </template>
              <!-- :outlined="realDays !== 1 || totalDays <= realDays" -->
            <v-chip
              :color="realDays === 1 || realDays > totalDays ? 'var(--text__gray-darken) !important' : 'var(--text__white) !important'"
              small
              label
              outlined
              :style="`border-color: ${realDays === 1 || realDays > totalDays ? 'var(--text__gray-darken) !important' : '#D7D7D7 !important'}`"
            >
              <span :style="`color: ${realDays === 1 || realDays > totalDays ? 'var(--text__white)' : 'var(--text__gray-darken)'}`">
                {{ realDays + "/" + totalDays }}
              </span>
            </v-chip>
          </div>
        </template>
      </v-card>
    </template>
    <v-card class="pb-2" v-if="isBlock === false && isEmpty === false && isFullRole">
      <v-list-item>
        <v-btn
          block
          plain
          :to="
            '/reservation/contract-detail?id=' +
              reservationId + '&tab=basic-information'
          "
          target="_blank"
        >
          {{ $t("reservation.reservationDetailsEdit") }}
        </v-btn>
      </v-list-item>
      <v-list-item>
        <v-btn
          block
          plain
          :to="
            '/reservation/contract-detail?id=' +
              reservationId + '&tab=billing-statement'
          "
          target="_blank"
        >
          {{ $t("reservation.editDetailedStatement") }}
        </v-btn>
      </v-list-item>
      <v-list-item>
        <v-btn block plain @click="redirectCRM" :disabled="!itemRoot.contract"> {{ $t("reservation.memberInformation") }} </v-btn>
      </v-list-item>
      <v-divider></v-divider>
     <div class="px-3 pb-2" v-if="checkStatus">
        <v-checkbox
          v-model="valueCheckIn"
          hide-details
          :label="$t('common.checkIn')"
        ></v-checkbox>
        <v-checkbox
          v-model="valueCheckOut"
          hide-details
          :label="$t('common.checkOut')"
        ></v-checkbox>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import gql from 'graphql-tag'
import { listContractColor } from '@/constants/contractColors'
import { mapMutations } from 'vuex'
import { isoDate } from '../../utils/dateUtil'

export default {
  name: 'GuestRoomItem',
  mounted () {
    this.getNumOfguest()
  },
  methods: {
    ...mapMutations(['setLoadingOverlayShow', 'setLoadingOverlayHide', 'setAlertSuccess']),
    redirectCRM () {
      const url = `${process.env.VUE_APP_URL_CRM}/customer-details/${this.itemRoot.contract.client.id}?typeClient=${this.itemRoot.contract.client.type}&fromPage=pms`
      window.open(url)
    },
    getContractColor (item) {
      if (item.includes('SP')) {
        item = 'SP'
      }
      return listContractColor[item]
    },
    getNumOfguest () {
      for (let i = 0; i < this.guests.length; i++) {
        if (this.guests[i].child) {
          this.numOfChild++
        } else {
          this.numOfAdult++
        }
        if (this.guests[i].bedShared) {
          this.numOfBedSharing++
        }
      }
    },
    async setCheckIn (id) {
      this.setLoadingOverlayShow()
      await this.$apollo.mutate({
        mutation: gql`
          mutation ($id: Int!) {
            checkInBooking(id: $id) {
              __typename
            }
          }
        `,
        variables: {
          id: parseInt(id)
        }
      }).then(data => {
        this.setLoadingOverlayHide()
        this.setAlertSuccess(this.$t('messages.successfulUpdate'))
      }).catch(error => {
        console.error(error)
        this.setLoadingOverlayHide()
      })
    },
    async setCheckOut (id) {
      await this.$gqlLoading(`
        mutation ($id: Int!) {
          checkOutBooking(id: $id) {
            __typename
          }
        }
        `, {
        id: parseInt(id)
      })

      this.setAlertSuccess(this.$t('messages.successfulUpdate'))
    },
    async cancelCheckIn (id) {
      this.setLoadingOverlayShow()
      await this.$apollo.mutate({
        mutation: gql`
        mutation ($id: Int!) {
          cancelCheckInBooking(id: $id) {
            __typename
          }
        }
        `,
        variables: {
          id: parseInt(id)
        }
      }).then(data => {
        this.setLoadingOverlayHide()
        this.setAlertSuccess(this.$t('messages.successfulUpdate'))
      }).catch(error => {
        console.error(error)
        this.setLoadingOverlayHide()
      })
    },
    async cancelCheckOut (id) {
      await this.$gqlLoading(`
        mutation ($id: Int!) {
          cancelCheckOutBooking(id: $id) {
            __typename
          }
        }
        `, {
        id: parseInt(id)
      })

      this.setAlertSuccess(this.$t('messages.successfulUpdate'))
    },
    recheckCheckInStatus () {
      this.checkStatus = false
      this.$nextTick(() => {
        this.checkStatus = true
      })
    },
    isoDate
  },
  computed: {
    valueCheckIn: {
      get () {
        return this.itemRoot.checkedInAt
      },
      async set (val) {
        try {
          if (val) {
            await this.setCheckIn(this.reservationId)
          } else {
            await this.cancelCheckIn(this.reservationId)
          }
        } finally {
          this.recheckCheckInStatus()
          this.$emit('reloadData')
        }
      }
    },
    valueCheckOut: {
      get () {
        return this.itemRoot.checkedOutAt
      },
      async set (val) {
        try {
          if (val) {
            await this.setCheckOut(this.reservationId)
          } else {
            await this.cancelCheckOut(this.reservationId)
          }
        } finally {
          this.recheckCheckInStatus()
          this.$emit('reloadData')
        }
      }
    }
  },
  data () {
    return {
      menu: false,
      numOfAdult: 0,
      numOfChild: 0,
      numOfBedSharing: 0,
      toDay: new Date(),
      checkStatus: true
    }
  },
  props: {
    isFullRole: {
      type: Boolean,
      default: false
    },
    reservationId: Number,
    contract: Object,
    roomName: String,
    roomType: String,
    isEmpty: {
      type: Boolean,
      default: false
    },
    hasLink: {
      type: Boolean,
      default: false
    },
    representative: Object,
    bookingTypeCode: String,
    bookingBarNote: String,
    guests: {
      type: Array,
      default: () => []
    },
    realDays: [Number, String],
    totalDays: [Number, String],
    checkComputed: Object,
    isRental: {
      type: Boolean,
      default: false
    },
    isParking: {
      type: Boolean,
      default: false
    },
    isBlock: {
      type: Boolean,
      default: false
    },
    hasRemarks: {
      type: Boolean,
      default: false
    },
    itemRoot: {
      required: true,
      default: () => {}
    },
    needsPetPolicyCheck: Boolean
  }
}
</script>

<style lang="scss" scoped>
* {
  color: var(--text__gray-darken);
  font-size: 12px;
}
.t-room-id {
  font-size: 16px !important;
  font-weight: 600;
}
.t-guest-name {
  font-size: 18px;
  line-height: 1;
  font-weight: bold;
  img {
    width: 14.07px;
    height: 14.07px;
  }
}
.t-landing {
  margin-left: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: solid 1px var(--border__gray-darken);
}
.small-text {
  font-size: 11px !important;
}
::v-deep {
  &.v-chip:not(.v-chip--outlined) {
    color: var(--text__white);
  }
  &.t-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 139px !important;
  }
  &.v-radio label {
    color: var(--text__dark);
  }
  &.v-btn--block.v-btn--plain {
    color: var(--text__dark);
    padding: 0;
    .v-btn__content {
      justify-content: flex-start;
    }
  }
  .v-input--checkbox {
    label {
      font-weight: 500 !important;
      font-size: 15px !important;
      color: #000000 !important
    }
  }
  .v-list-item .v-btn__content {
    font-size: 13px !important;
    opacity: 1 !important;
  }
}
::v-deep {
  .v-chip {
    height: 14px !important;
    width: 50px !important;
  }
  .v-chip__content {
    margin: 0 auto;
    text-align: center !important;
    font-size: 8px !important;
  }
  .v-chip:not(.v-chip--outlined) {
    width: auto !important;
  }
}

.guest-panel-room {
  &.is-block {
    background-color: var(--bg__gray-very-lighten);
  }
}
</style>
