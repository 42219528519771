<template>
  <v-select :items="facilityList" item-text="name"
    item-value="id" :value="value" dense small
    color="var(--text__gray)" outlined hide-details
    class="v-select-custom ml-1 facility-list"
    @input="$emit('input', $event)"
  />
</template>

<script>
import { FACILITY_LIST_FOR_SELECT } from '@/api/graphql/facility-settings'

export default {
  props: {
    value: Number
  },
  data () {
    return {
      facilityList: []
    }
  },
  mounted () {
    this.getFacilityList()
  },
  methods: {
    async getFacilityList () {
      const result = await this.$gql(FACILITY_LIST_FOR_SELECT)
      this.facilityList = result.data.facilityList
      if (this.facilityList.length === 1) {
        this.$emit('input', this.facilityList[0].id)
      }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-select-custom {
    input::placeholder {
      color: var(--text__dark) !important
    }
  }
}
</style>
