export const BOOKING_LIST = `
  query($filter: BookingListFilterInput, $orderBy: [OrderByInput!]) {
    bookingList(
      filter: $filter
      orderBy: $orderBy
      loadClient: true
    ) {
      items {
        clientId
        checkedOutAt #nullなら未アウトのタグ
        checkedInAt # nullなら未インのタグ
        checkInDate #これが当日の場合、日時更新後のみの表示すること
        checkOutDate #これが当日の場合、日時更新前のみに表示すること
        computed {
          invoiced # これがFalseであれば未精算
          needsInvoice 
        }
        hasAgreedPetPolicy
        room {
          id
          name #部屋名
          roomType {
            id
            name # 部屋タイプ名
            acceptsPet
          }
        }
        blockRoom #ブロックを意味する
        id #予約ID
        bookingGroupId
        remarks
        bookingType {
          code
          id #予約タイプID
          name #予約タイプ名
        }
        bookingBarNote #予約バー内容
        guests {
          child
          bedShared
        }
        contract {
          id
          clientId
          client {
            id
            memberId # 会員ID
            name
            type
          }
        }
        representative {
          name
        }
        computedForList {
          hasParking #Boolean! 駐車場あり
          hasRentalItem #Boolean! 貸出品あり
        }
      }
      total
      hasMore
    }
  }
`

export const ROOM_TYPE_LIST_WITH_FACILITY = `
query ($facilityId: Int!) {
  roomTypeList(facilityId: $facilityId)
  {
    id
    name
    order
    foreColor
    rooms {
      id
      name
      order
      isListed
    }
  }
}
`
